/* Banner component styles */
.banner {
  background: linear-gradient(
    to right,
    rgba(117, 207, 230, 1) 3%,
    rgb(21, 99, 118) 2%
  );
  color: #fff;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 50px;
  width: 50vw;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 16px;
  margin-left: 16vw;
  margin-top: 20px;
  z-index: 1000;
  font-family: arial;
  @media (max-width: 800px) {
    display: none;
  }
  @media (max-width: 960px) {
    margin-left: 20vw;
  }
}

.banner-message {
  text-align: center;
  margin: 0;
  font-size: 18px;
}

.banner-close-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 10px;
}

.banner-close-button:hover {
  background-color: #c0392b;
}
