body {
 margin: 0;
 /* background-color: #fff; */
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.jRFJkP {
 background-color: transparent !important;
 color: #ff7300 !important;
 border: none !important;
 box-shadow: none !important ;
}
.jRFJkP:hover:enabled,
.jRFJkP:focus:enabled {
 background-color: transparent !important;
 color: #ff7300 !important;
}
.lcUbOd {
 background-color: #ff7300 !important;
 box-shadow: none !important;
}

.cNNJIt {
 box-shadow: none !important;
 background-color: transparent !important;
}
.eaptOe {
 box-shadow: 0 0 1px 2px #ff7300 !important;
}

.fPDpuU {
 margin: 0 !important;
}

.transaction_div {
 overflow-x: hidden !important;
}

.hoverPointer:hover{
    cursor: pointer;
}

/* URI */
.uri .MuiTab-textColorInherit.Mui-selected{
	color: #369BB4;
}
.uri .MuiTab-textColorInherit{
	color: #C9E5EC;
}
/* URI End */
