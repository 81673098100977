.switch {
 height: 30px;
 width: 30px;
 border-radius: 30px;
 display: flex;
 display: flex;
 align-items: center;
 justify-content: center;
}
.switch.checked {
 background-color: #237e95;
 color: #fff;
}
.switch.unchecked {
 background-color: #237e95;
 color: #fff;
}
